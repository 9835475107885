import {Injectable, TemplateRef} from '@angular/core';
import {SlidePanelStackController} from './slide-panel-stack-controller';
import {Router} from '@angular/router';
import {SlidePanelDefinitions, SlidePanelTypeKey} from '../../definitions/definitions';
import {PageThroughItem, SlidePanelOptions} from './slide-panel';
import {SlidePanelDefinition, SlidePanelSize} from '../../definitions/definitions-base';
import {fromEvent, merge, of} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {breakpoints} from '../../@theme/breakpoints';

@Injectable({
    providedIn: 'root',
})
export class SlidePanelService {
    canDouble = false;

    get panelChange() {
        return this.stackCtrl.panelChange$;
    }

    get activePanel$() {
        return this.stackCtrl.activePanel$;
    }

    get panelStack() {
        return this.stackCtrl.panelStack;
    }

    get activePanel() {
        return this.panelStack[this.panelStack.length - 1];
    }

    get isOpen() {
        return !!this.panelStack.length;
    }

    get size() {
        return this.activePanel?.size || 'md';
    }

    constructor(private stackCtrl: SlidePanelStackController,
                private router: Router) {
        merge(of(null), fromEvent(window, 'resize').pipe(debounceTime(100))).subscribe(() => {
            this.canDouble = window.innerWidth >= breakpoints.xl;
        });
    }

    add(templateRef: TemplateRef<any>, options: SlidePanelOptions = {}) {
        this.stackCtrl.push({...options, templateRef});
    }

    addComponent(type: SlidePanelTypeKey, id: number | number[], options?: SlidePanelOptions);
    addComponent(type: SlidePanelTypeKey, id: number | number[], pageThrough?: PageThroughItem[], options?: SlidePanelOptions);
    addComponent(type: SlidePanelTypeKey, id: number | number[], pageThrough?: PageThroughItem[], triggerLocationChange?: boolean, options?: SlidePanelOptions);
    addComponent(type: SlidePanelTypeKey, id: number | number[], optionsOrPageThrough?: SlidePanelOptions | PageThroughItem[], optionsOrTriggerLocationChange?: SlidePanelOptions | boolean, options?: SlidePanelOptions) {
        options =
            options ||
            typeof optionsOrTriggerLocationChange === 'object' && optionsOrTriggerLocationChange ||
            !Array.isArray(optionsOrPageThrough) && optionsOrPageThrough ||
            {};
        const pageThrough = Array.isArray(optionsOrPageThrough) ? optionsOrPageThrough : null;
        const def = SlidePanelDefinitions[type];

        if (def.path) {
            let url = Array.isArray(id) ? def.getPath(...id) : def.getPath(id);
            if (options?.selectedTab) url += `?tab=${options.selectedTab}#${options.selectedTab}`;
            this.stackCtrl.slidePanelOptions = options;
            return this.router.navigateByUrl(url, {state: {pageThrough}});
        }

        return this.stackCtrl.loadAndPushComponentPanel({
            id: Array.isArray(id) ? id[id.length - 1] : id,
            size: def.size,
            verticalAlign: def.verticalAlign,
            showFab: def.showFab,
            nextPrevBtnText: def.nextPrevBtnText,
            customMarkup: def.customMarkup,
            config: def.config,
            pageThrough,
            ...options,
        }, def.loadComponent, def.loadChildren).then(() => {
            if (options?.selectedTab) window.location.hash = options.selectedTab;
            return true;
        });
    }

    addEmbed(url: string, needDownload: boolean, showDownloadBtn: boolean, mimeType?: string, size: SlidePanelSize = 'md', panelInfo?: string) {
        const componentInputs: {url?: string; needDownload?: boolean; showDownloadBtn; mimeType?: string; panelInfo?: string} = {url, needDownload, showDownloadBtn, mimeType, panelInfo};
        const slidePanelOptions: SlidePanelOptions = {componentInputs, size};
        this.addComponent('EMBED', null, null, false, slidePanelOptions);
    }

    back() {
        if (this.panelStack.length < 2) return this.close();

        if (this.activePanel?.url) {
            const lastUrlPanel = this.panelStack.slice(0, this.panelStack.length - 1).reverse().find(x => x.url);
            this.stackCtrl.skipNavigation = true;
            this.router.navigateByUrl(lastUrlPanel?.url ?? this.stackCtrl.rootUrl ?? this.activePanel.defRootUrl);
        }

        this.stackCtrl.pop();
    }

    close() {
        if (!this.isOpen) return;

        const firstUrlPanel = this.panelStack.find(x => x.url);
        const rootUrl = this.stackCtrl.rootUrl ?? firstUrlPanel?.defRootUrl;

        this.stackCtrl.empty(); // invoke before router navigation to make navigating to a slide panel type URL when closing work

        if (firstUrlPanel) this.router.navigateByUrl(rootUrl);
    }

    next() {
        if (this.activePanel.nextFn && this.activePanel.hasNextFn()) return this.activePanel.nextFn();
    }

    prev() {
        if (this.activePanel.prevFn && this.activePanel.hasPrevFn()) return this.activePanel.prevFn();
    }

    componentNext() {
        const target = this.getNextComponentInfo();

        if (!target) return;

        const pageThrough = this.activePanel.pageThrough;
        pageThrough.forEach(x => x.active = x === target.pageThroughItem);
        if (this.activePanel.id !== target.pageThroughItem.id) {
            this.addComponent(this.getPanelType(), target.pageThroughItem.id, pageThrough).then(() => {
                this.panelStack.splice(this.panelStack.length - 2, 1);
            });
        }
    }

    componentPrev() {
        const target = this.getNextComponentInfo(-1);

        if (!target) return;

        const type = this.getPanelType();
        const pageThrough = this.activePanel.pageThrough;
        pageThrough.forEach(x => x.active = x === target.pageThroughItem);
        if (this.activePanel.id !== target.pageThroughItem.id) {
            this.addComponent(type, target.pageThroughItem.id, pageThrough).then(() => {
                const active = this.panelStack.pop();
                this.panelStack.splice(this.panelStack.length - 1, 0, active);
                setTimeout(() => this.panelStack.pop());
            });
        }
    }

    getNextComponentInfo(diff = 1): {index: number; pageThroughItem: PageThroughItem} {
        const pageThrough = this.activePanel.pageThrough;

        const ai = pageThrough.findIndex(x => x.active && x.id === this.activePanel.id);
        const ci = ai >= 0 ?
            ai :
            pageThrough.findIndex(x =>
                x.id === this.activePanel.id &&
                (!this.activePanel.pagingSecondaryId || this.activePanel.pagingSecondaryId === x.secondaryId)
            );

        if (ci < 0) return null;

        const i = ci + diff;

        return i >= 0 && i < pageThrough.length ? {index: i, pageThroughItem: pageThrough[i]} : null;
    }

    /** @deprecated Use add() instead */
    open(templateRef: TemplateRef<any>, options: SlidePanelOptions = {}) {
        this.add(templateRef, options);
    }

    /** @deprecated Use back() instead */
    backHistory() {
        this.back();
    }

    /** @deprecated Use close() instead */
    clear() {
        this.close();
    }

    getPanelType(panel = this.activePanel) {
        if (!panel) return null;

        const segments = panel.url.split('/').filter(x => x);
        return (Object.values(SlidePanelDefinitions) as SlidePanelDefinition[]).find(def => {
            if (!def.path) return false;

            const defPathSegments = def.path.split('/');
            return segments.length === defPathSegments.length &&
                defPathSegments.every((s, i) => s.startsWith(':') || s === segments[i]);
        })?.key as SlidePanelTypeKey;
    }
}
